























































































































































import Vue from "vue";
import _ from "lodash";

import Toc from "@/components/widgets/Toc.vue";

const VIEW_TITLE = "ASQ: docs";

export default Vue.extend({
  name: "DocsView",
  components: {
    Toc,
  },
  data() {
    return {
      outline: {
        about: {
          ref: "about",
          label: "About",
          focus: false,
        },
        terminology: {
          ref: "terminology",
          label: "Terminology",
          focus: false,
        },
        ents: {
          ref: "ents",
          label: "Entities",
          focus: false,
        },
        params: {
          ref: "params",
          label: "Parameters",
          focus: false,
        },
        stages: {
          ref: "stages",
          label: "Stages",
          focus: false,
        },
        components: {
          ref: "components",
          label: "Components",
          focus: false,
        },
        "triple-literature-evidence": {
          ref: "triple-literature-evidence",
          label: "Triple and literature evidence",
          focus: false,
        },
        "assoc-evidence": {
          ref: "assoc-evidence",
          label: "Association evidence",
          focus: false,
        },
        scores: {
          ref: "scores",
          label: "Evidence scores",
          focus: false,
        },
        analysis: {
          ref: "analysis",
          label: "MedRxiv analysis",
          focus: false,
        },
      },
      stageDocs: _.chain([
        // be verbose
        "queryParsing",
        "tripleSelect",
        "entHarmonizationOntology",
        "evidenceSummary",
        "tripleLiteratureEvidence",
        "assocEvidence",
      ])
        .map((key) => this.$store.state.docs.general[key])
        .value(),
    };
  },
  computed: {
    outlineItems(): Array<Record<string, any>> {
      return _.chain(this.outline)
        .mapValues((item) => item)
        .value();
    },
    tripleEvidenceDocs(): Record<string, Array<string>> {
      const baseDocs = this.$store.state.evidence.tripleLiteratureEvidenceTypes;
      const res = _.chain(baseDocs)
        .mapValues((items) => {
          const res = _.chain(items).values().value();
          return res;
        })
        .value();
      return res;
    },
    assocEvidenceDocs(): Record<string, Array<string>> {
      const baseDocs = this.$store.state.evidence.assocEvidenceTypes;
      const res = _.chain(baseDocs)
        .mapValues((items) => {
          const res = _.chain(items).values().value();
          return res;
        })
        .value();
      return res;
    },
    componentDocs(): string[] {
      const res = this._.chain(this.$store.state.docs.plots).values().value();
      return res;
    },
  },
  mounted: function () {
    document.title = VIEW_TITLE;
    this.$store.dispatch("queryStage/setQueryMode", "off");
  },
  methods: {
    onIntersect(entries): void {
      // console.log(entries);
      const focus = entries[0].isIntersecting;
      const id = entries[0].target.id;
      this.outline[id].focus = focus;
    },
    jump(ref): void {
      const target = this.$refs[ref];
      // @ts-ignore
      this.$vuetify.goTo(target);
    },
  },
});

import { render, staticRenderFns } from "./Docs.vue?vue&type=template&id=66abf04a&scoped=true&lang=pug&"
import script from "./Docs.vue?vue&type=script&lang=ts&"
export * from "./Docs.vue?vue&type=script&lang=ts&"
import style0 from "./Docs.vue?vue&type=style&index=0&id=66abf04a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66abf04a",
  null
  
)

export default component.exports